import React from 'react';
import { Link } from 'gatsby';

import welcomeStyles from './Welcome.module.scss';

const Welcome = () => {
  const { wrap, content, link } = welcomeStyles;

  return (
    <div className={wrap}>
      <div className={content}>
        hi. my name is{' '}
        <Link to="/about" className={link}>
          jonathan
        </Link>
        . i'm a <span className="secondary-color">software developer</span> and
        lawyer{' '}
        <Link to="/#work" className={link}>
          working
        </Link>{' '}
        in new york city.
      </div>
    </div>
  );
};

export default Welcome;
