import React from 'react';
import { Link } from 'gatsby';

import workStyles from './Work.module.scss';
import zendesk from '../../../static/zendesk.png';
import item1 from '../../../static/items/item1.png';
import item2 from '../../../static/items/item2.png';
import item5 from '../../../static/items/item5.png';
import item8 from '../../../static/items/item8.png';

const Work = () => {
  const {
    wrap,
    sectionLabel,
    list,
    workItem,
    imageWrap,
    overlayLeft,
    image,
    overlayText,
    mainTitle,
    mainTitleMobile,
    techStackGroup,
    detailsWrap,
    details,
    detailsCategory,
    btnGroup,
    link,
    workItemReverse,
    overlayRight,
    workGallery,
    galleryTitle,
    gridItems,
    item,
    overlayCorner,
  } = workStyles;

  return (
    <div className={wrap}>
      <div className={sectionLabel}>
        some of my <span className="bolder secondary-color">projects</span>
      </div>
      <div className={list}>
        <div className={workItem}>
          <div className={imageWrap}>
            <div className={overlayLeft}>
              <a href="#">
                <div className={image}>
                  <img src={zendesk} alt="Zendesk" />
                </div>
                <div className={overlayText}>
                  <div className={techStackGroup}>
                    <div className="pill-white">Node</div>
                    <div className="pill-white">Express</div>
                    <div className="pill-white">MongoDB</div>
                    <div className="pill-white">React</div>
                    <div className="pill-white">Redux</div>
                  </div>
                </div>
              </a>
            </div>
            <h1 className={mainTitle}>
              <Link to="#" className={link}>
                Zendesk
              </Link>
            </h1>
          </div>
          <div className={detailsWrap}>
            <div className={details}>
              <p>
                <span className="bolder primary-color">Zendesk</span> is a
                customer support platform that empowers organization and
                relationships by allowing companies to connect with their
                customers on any channel.
              </p>
              <h6 className={detailsCategory}>ENTERPRISE</h6>
              <div className={btnGroup}>
                <a href="#" className="btn-primary">
                  View Code
                </a>
                <a href="#" className="btn-secondary-inverse">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <h1 className={mainTitleMobile}>
            <Link to="#" className={link}>
              Zendesk
            </Link>
          </h1>
        </div>
        <div className={workItemReverse}>
          <div className={imageWrap}>
            <div className={overlayRight}>
              <a href="#">
                <div className={image}>
                  <img src={zendesk} alt="Zendesk" />
                </div>
                <div className={overlayText}>
                  <div className={techStackGroup}>
                    <div className="pill-white">Node</div>
                    <div className="pill-white">Express</div>
                    <div className="pill-white">MySQL</div>
                    <div className="pill-white">Gatsby</div>
                    <div className="pill-white">Stripe</div>
                  </div>
                </div>
              </a>
            </div>
            <h1 className={mainTitle}>
              <Link to="#" className={link}>
                Earlybird
              </Link>
            </h1>
          </div>
          <div className={detailsWrap}>
            <div className={details}>
              <p>
                <span className="bolder primary-color">Earlybird</span> is a
                customer support platform that empowers organization and
                relationships by allowing companies to connect with their
                customers on any channel.
              </p>
              <h6 className={detailsCategory}>Social</h6>
              <div className={btnGroup}>
                <a href="#" className="btn-primary">
                  View Code
                </a>
                <a href="#" className="btn-secondary-inverse">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <h1 className={mainTitleMobile}>
            <Link to="#" className={link}>
              Earlybird
            </Link>
          </h1>
        </div>
        <div className={workItem}>
          <div className={imageWrap}>
            <div className={overlayLeft}>
              <a href="#">
                <div className={image}>
                  <img src={zendesk} alt="Zendesk" />
                </div>
                <div className={overlayText}>
                  <div className={techStackGroup}>
                    <div className="pill-white">Firebase</div>
                    <div className="pill-white">GraphQL</div>
                    <div className="pill-white">PostgreSQL</div>
                    <div className="pill-white">React</div>
                    <div className="pill-white">Redux</div>
                  </div>
                </div>
              </a>
            </div>
            <h1 className={mainTitle}>
              <Link to="#" className={link}>
                Emaily
              </Link>
            </h1>
          </div>
          <div className={detailsWrap}>
            <div className={details}>
              <p>
                <span className="bolder primary-color">Emaily</span> is a
                customer support platform that empowers organization and
                relationships by allowing companies to connect with their
                customers on any channel.
              </p>
              <h6 className={detailsCategory}>ENTERPRISE</h6>
              <div className={btnGroup}>
                <a href="#" className="btn-primary">
                  View Code
                </a>
                <a href="#" className="btn-secondary-inverse">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <h1 className={mainTitleMobile}>
            <Link to="#" className={link}>
              Emaily
            </Link>
          </h1>
        </div>

        {/* Work Gallery */}
        <div className={workGallery}>
          <div className="container">
            <h1 className={galleryTitle}>more...</h1>
            <div className={gridItems}>
              <div className={item}>
                <div className={overlayCorner}>
                  <a href="#">
                    <div className={image}>
                      <img src={item1} alt="" />
                    </div>
                    <div className={overlayText}>
                      <h1>Grabient</h1>
                    </div>
                  </a>
                </div>
              </div>
              <div className={item}>
                <div className={overlayCorner}>
                  <a href="#">
                    <div className={image}>
                      <img src={item2} alt="" />
                    </div>
                    <div className={overlayText}>
                      <h1>REI Adventures</h1>
                    </div>
                  </a>
                </div>
              </div>
              <div className={item}>
                <div className={overlayCorner}>
                  <a href="#">
                    <div className={image}>
                      <img src={item5} alt="" />
                    </div>
                    <div className={overlayText}>
                      <h1>Pulsd</h1>
                    </div>
                  </a>
                </div>
              </div>
              <div className={item}>
                <div className={overlayCorner}>
                  <a href="#">
                    <div className={image}>
                      <img src={item8} alt="" />
                    </div>
                    <div className={overlayText}>
                      <h1>Foursquare</h1>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End of Work Gallery */}
      </div>
    </div>
  );
};

export default Work;
