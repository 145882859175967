import React from 'react';
import { Link } from 'gatsby';

import contactStyles from './Contact.module.scss';

const Contact = () => {
  const { wrap, content, meLink, link } = contactStyles;

  return (
    <div className={wrap}>
      <div className={content}>
        you can contact{' '}
        <Link to="/about" className={meLink}>
          me
        </Link>{' '}
        via{' '}
        <a href="#" className={link}>
          mail
        </a>{' '}
        or{' '}
        <a href="#" className={link}>
          linkedin
        </a>{' '}
        or{' '}
        <a href="#" className={link}>
          twitter
        </a>
        .
      </div>
    </div>
  );
};

export default Contact;
