import React from 'react';

import Layout from '../components/Layout';
import Welcome from '../components/Welcome';
import Work from '../components/Work';
import Contact from '../components/Contact';

const HomePage = () => {
  return (
    <Layout>
      <header>
        <Welcome />
      </header>

      <section id="work">
        <Work />
      </section>

      <section id="contact">
        <Contact />
      </section>
    </Layout>
  );
};

export default HomePage;
